<div class="card">
  <app-menu-bar></app-menu-bar>
</div>

<div class="justify-content-center flex">
  <p-card header="Login to SmolvaultUI">
    <div class="field">
      <label for="username" class="mr-2">Username</label>
      <input id="username" pInputText type="text" [formControl]="username" />
    </div>

    <div class="field">
      <label for="password" class="mr-2">Password</label>
      <p-password
        [feedback]="false"
        class="py-1"
        [formControl]="password"
        (keydown.enter)="onLogin()"
      ></p-password>
    </div>

    <div class="justify-content-center flex">
      <p-button label="Login" (click)="onLogin()"></p-button>
    </div>
  </p-card>
</div>
