import { Routes } from '@angular/router';
import { FileMetadataViewerComponent } from './file-metadata-viewer/file-metadata-viewer.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FilesTableComponent } from './files-table/files-table.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'file-upload', component: FileUploaderComponent },
  { path: 'files', component: FilesTableComponent },
  { path: 'file-metadata-viewer', component: FileMetadataViewerComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
