export const environment = {
  production: true,
  'smolvault-api-url': 'https://smolvault-api.fullerzz.dev',
  sentryConfig: {
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/fullerzz\.dev/,
      'https://smolvault-api.fullerzz.dev/*',
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
};
