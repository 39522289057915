import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

const sentryConfig = environment.sentryConfig;

Sentry.init({
  dsn: 'https://f481a6127bc93d8848ca842a9ef10cb7@o4508033350434816.ingest.us.sentry.io/4508037353439232',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: sentryConfig.tracesSampleRate,
  tracePropagationTargets: sentryConfig.tracePropagationTargets,
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
