import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { FileMetadataViewerComponent } from '../file-metadata-viewer/file-metadata-viewer.component';
import { MenuBarComponent } from '../menu-bar/menu-bar.component';
import { SmolvaultFilesService } from '../services/smolvault-files.service';
import { FileUpload } from '../types';
import { SearchComponent } from './search/search.component';

@Component({
  selector: 'app-files-table',
  standalone: true,
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    FileMetadataViewerComponent,
    TableModule,
    MenuBarComponent,
    SearchComponent,
    ToastModule,
  ],
  providers: [ConfirmationService, MessageService, Router],
  templateUrl: './files-table.component.html',
  styleUrl: './files-table.component.css',
})
export class FilesTableComponent implements OnInit {
  files: FileUpload[] = [];

  constructor(
    private filesService: SmolvaultFilesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.filesService.getFiles().subscribe((data) => {
      this.files = data;
    });
  }

  deleteFile(file: FileUpload) {
    this.filesService.deleteFile(file.file_name).subscribe(() => {
      this.files = this.files.filter((f) => f.file_name !== file.file_name);
    });
  }

  confirmDelete(file: FileUpload) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this file?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => {
        this.deleteFile(file);
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: `${file.file_name} deleted`,
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Cancelled',
          detail: `${file.file_name} not deleted`,
        });
      },
    });
  }

  navigateToFileMetadata(file: FileUpload) {
    console.log('Calling router.navigate');
    this.router.navigate(['../file-metadata-viewer'], {
      relativeTo: this.route,
      queryParams: {
        fileName: file.file_name,
        fileSize: file.size,
        uploadTimestamp: file.upload_timestamp,
        tags: file.tags_list.join(', '),
        link: file.link,
      },
    });
  }

  onSearchChange(event: string | null) {
    console.log('onSearchChange()');
    console.log(event);
    if (event != null) {
      this.filesService.searchTag(event).subscribe((data) => {
        this.files = data;
      });
    }
  }
}
