import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FileUpload, FileUploads } from '../types';

@Injectable({
  providedIn: 'root',
})
export class SmolvaultFilesService {
  private apiUrl: string;
  constructor(private http: HttpClient) {
    this.apiUrl = environment['smolvault-api-url'];
  }

  getFiles(): Observable<FileUploads> {
    console.log('retrieving files...'); // debug
    return this.http.get<FileUploads>(`${this.apiUrl}/files`);
  }

  searchTag(tag: string): Observable<FileUploads> {
    const params = new HttpParams().set('tag', tag);
    return this.http.get<FileUploads>(`${this.apiUrl}/files/search`, {
      params: params,
    });
  }

  downloadFile(fileName: string): Observable<Blob> {
    const params = new HttpParams().set('filename', fileName);
    return this.http.get(`${this.apiUrl}/file/original`, {
      params: params,
      responseType: 'blob',
    });
  }

  uploadFile(file: File, tags: string | null): Observable<FileUpload> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (tags) {
      formData.append('tags', tags);
    }
    return this.http.post<FileUpload>(`${this.apiUrl}/file/upload`, formData);
  }

  deleteFile(fileName: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/file/${encodeURIComponent(fileName)}`,
    );
  }

  updateTags(fileName: string, tags: string[]): Observable<JSON> {
    return this.http.patch<JSON>(
      `${this.apiUrl}/file/${encodeURIComponent(fileName)}/tags`,
      {
        tags: tags,
      },
    );
  }
}
