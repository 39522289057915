import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MenuBarComponent } from './../menu-bar/menu-bar.component';
import { SmolvaultFilesService } from './../services/smolvault-files.service';

@Component({
  selector: 'app-file-metadata-viewer',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    MenuBarComponent,
    CardModule,
    ChipModule,
    InputSwitchModule,
    InputTextModule,
    ReactiveFormsModule,
    ToastModule,
  ],
  providers: [ConfirmationService, MessageService, Router],
  templateUrl: './file-metadata-viewer.component.html',
  styleUrl: './file-metadata-viewer.component.css',
})
export class FileMetadataViewerComponent implements OnInit {
  @Input() fileName = '';
  @Input() fileSize = 0;
  @Input() uploadTimestamp = '';
  @Input() tags = '';
  @Input() link = '';
  escapedLink = '';
  tagsList: string[] = [];
  editMode = new FormControl(false);
  editable = false;
  newTag = new FormControl('');
  modalVisible = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private filesService: SmolvaultFilesService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.fileName = params['fileName'];
      this.fileSize = params['fileSize'];
      this.uploadTimestamp = params['uploadTimestamp'];
      this.tags = params['tags'];
      this.tagsList = this.parseTags(params['tags']);
      this.link = params['link'];
    });
    this.escapedLink = decodeURIComponent(this.link);
  }

  parseTags(tags: string): string[] {
    return tags.split(',');
  }

  onDownload() {
    this.filesService.downloadFile(this.fileName).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, this.fileName);
    });
  }

  onTagRemoval(tag: string) {
    // FIXME: Removes more than 1 tag
    console.log('Removing tag:', tag);
    this.tagsList = this.tagsList.filter((t) => t !== tag);
  }

  onEditModeChange(event: InputSwitchChangeEvent) {
    this.editable = event.checked;
  }

  onNewTagAddition() {
    if (this.newTag.value !== '' && this.newTag.value !== null) {
      this.tagsList.push(this.newTag.value);
      this.newTag.setValue('');
    }
  }

  onCancel() {
    this.editable = false;
    this.editMode.setValue(false);
    this.tagsList = this.parseTags(this.tags);
    this.messageService.add({
      severity: 'info',
      summary: 'Cancelled',
      detail: 'Tag updates discarded',
    });
  }

  onSave() {
    console.log('Saving tags:', this.tagsList);
    this.editable = false;
    this.editMode.setValue(false);
    this.tags = this.tagsList.join(', ');

    this.filesService
      .updateTags(this.fileName, this.tagsList)
      .subscribe((data: JSON) => {
        console.log('Received response from API:', data);
        this.messageService.add({
          severity: 'info',
          summary: 'Saved',
          detail: 'Tag updates saved',
        });
      });
  }

  showModalDialog() {
    this.modalVisible = true;
  }
}
