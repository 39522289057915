<div class="card justify-content-center align-items-center flex gap-3">
  <p-floatLabel>
    <input
      pInputText
      id="searchTag"
      [formControl]="searchValue"
      (input)="onInput()"
    />
    <label for="searchTag">Tag name</label>
  </p-floatLabel>

  <p-button
    label="Search"
    icon="pi pi-check"
    [loading]="loading"
    (onClick)="onButtonClick()"
    severity="contrast"
    size="small"
  />
</div>
