import { Component } from '@angular/core';
import { FilesTableComponent } from '../files-table/files-table.component';
import { MenuBarComponent } from '../menu-bar/menu-bar.component';
import { Router } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [FilesTableComponent, MenuBarComponent, NgOptimizedImage],
  providers: [Router],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {}
