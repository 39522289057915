<main class="main">
  <div class="card">
    <app-menu-bar></app-menu-bar>
  </div>

  <p>home works!</p>
  <div class="align-items-center justify-content-center flex flex-wrap">
    <div
      class="fadeinright animation-duration-1000 animation-iteration-1 align-items-center justify-content-center flex"
    >
      <img
        ngSrc="doge.jpg"
        alt="doge"
        width="525"
        height="700"
        priority
        placeholder="blur"
      />
    </div>
  </div>
</main>
