import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import {
  FileSelectEvent,
  FileUploadHandlerEvent,
  FileUploadModule,
} from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { SplitterModule } from 'primeng/splitter';
import { ToastModule } from 'primeng/toast';
import { MenuBarComponent } from '../menu-bar/menu-bar.component';
import { SmolvaultFilesService } from '../services/smolvault-files.service';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    CardModule,
    InputTextModule,
    FileUploadModule,
    FloatLabelModule,
    MenuBarComponent,
    ReactiveFormsModule,
    SplitterModule,
    ToastModule,
  ],
  providers: [Router, SmolvaultFilesService, MessageService],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.css',
})
export class FileUploaderComponent {
  formInvalid = false;
  fileSelected = false;
  tags = new FormControl('');
  constructor(
    private filesService: SmolvaultFilesService,
    private router: Router,
    private messageService: MessageService,
  ) {}

  uploadHandler(event: FileUploadHandlerEvent) {
    console.log('Using custom file upload mode'); // debug
    this.showToast();
    for (const file of event.files) {
      this.filesService.uploadFile(file, this.tags.value).subscribe((data) => {
        console.log('File uploaded');
        console.log(data);
        this.resetForm();
        this.router.navigate(['/files']);
      });
    }
  }

  onSelect(event: FileSelectEvent) {
    console.log(`onSelect(): ${event}`); // debug
    this.fileSelected = true;
    this.checkValid();
  }

  checkValid() {
    if (this.tags.value === undefined || this.tags.value === '') {
      this.formInvalid = true;
    } else {
      this.formInvalid = false;
    }
  }

  resetForm() {
    this.tags.reset();
    this.fileSelected = false;
    this.checkValid();
  }

  showToast() {
    this.messageService.add({
      severity: 'info',
      summary: 'Upload Started',
      detail: 'Uploading file to smolvault',
    });
  }
}
