import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Token } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticated: boolean;
  loginEventEmitter = new EventEmitter<boolean>();
  loginRequiredEventEmitter = new EventEmitter<boolean>();
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.apiUrl = environment['smolvault-api-url'];
    this.authenticated = this.isAuthenticated();
  }

  login(username: string, password: string) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    this.http
      .post<Token>(`${this.apiUrl}/token`, formData)
      .subscribe((resp: Token) => {
        console.log('AuthService: Logged in');
        console.log(resp);
        this.setToken(resp.access_token);
        this.authenticated = true;
        this.loginEventEmitter.emit(this.authenticated);
      });
  }

  logout() {
    this.clearToken();
    this.loginEventEmitter.emit(false);
    this.router.navigate(['/home']);
  }

  refreshToken() {
    this.loginRequiredEventEmitter.emit(true);
  }

  clearToken() {
    console.log('Removing token from localStorage');
    localStorage.removeItem('token');
    this.authenticated = false;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token !== null;
  }

  loginRedirect() {
    this.authenticated = false;
    this.loginRequiredEventEmitter.emit(true);
    this.router.navigate(['/login']);
  }

  getToken(): string | null {
    // TODO: Implement refresh token logic
    console.log('AuthService: getToken()');
    const token = localStorage.getItem('token');
    console.log(`token from localStorage: ${token}`);
    return token;
  }

  setToken(token: string) {
    console.log(`AuthService: setToken()\ntoken: ${token}`);
    localStorage.setItem('token', token);
  }
}
