<div class="relative mb-3">
  <p-tabMenu [model]="items">
    <ng-template pTemplate="item" let-item>
      <ng-container *ngIf="item.route; else elseBlock">
        <a [routerLink]="item.route" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="ml-2">
            {{ item.label }}
          </span>
        </a>
      </ng-container>
      <ng-template #elseBlock>
        <a [href]="item.url" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="ml-2">
            {{ item.label }}
          </span>
        </a>
      </ng-template>
    </ng-template>
  </p-tabMenu>

  <div class="absolute right-0 top-0">
    <!-- <p-avatar icon="pi pi-user" styleClass="mr-2" size="large" shape="circle" /> -->
    @if (loggedIn === true) {
      <p-button
        label="LOG OUT"
        icon="pi pi-sign-out"
        size="small"
        class="mr-2 mt-2"
        [outlined]="true"
        severity="info"
        (click)="onLogout()"
      />
    } @else {
      <p-button
        label="LOG IN"
        icon="pi pi-user"
        size="small"
        class="mr-2 mt-2"
        [outlined]="true"
        severity="info"
        (click)="onLogin()"
      />
    }
  </div>
</div>
