<div class="card">
  <app-menu-bar></app-menu-bar>
</div>

<p-toast></p-toast>

<div class="align-items-center justify-content-center grid grid-cols-3">
  <div class="col"></div>

  <p-card class="mt-5" [header]="fileName" [subheader]="uploadTimestamp">
    <div class="grid">
      <div class="col">
        <p-button
          pRipple
          label="Download"
          severity="help"
          (onClick)="onDownload()"
        ></p-button>
      </div>
      <div class="col">
        <div
          id="editSwitch"
          class="card align-items-center justify-content-end mb-3 flex pt-2"
        >
          <label for="editSwitch" class="mr-2">Edit Mode</label>
          <p-inputSwitch
            [formControl]="editMode"
            class="justify-content-end"
            (onChange)="onEditModeChange($event)"
          />
        </div>
      </div>
    </div>

    @if (editable) {
      <div class="flex-column my-2 flex gap-2">
        <label for="newTag">New Tag</label>
        <input
          pInputText
          id="newTag"
          variant="filled"
          aria-details="Enter a new tag and hit enter."
          [formControl]="newTag"
          (keydown.enter)="onNewTagAddition()"
        />
      </div>
    }

    <div
      class="card align-items-center mt-2 flex flex-wrap justify-center gap-2"
    >
      @for (tag of tagsList; track $index) {
        <p-chip
          label="{{ tag }}"
          [removable]="editable"
          (onRemove)="onTagRemoval(tag)"
        ></p-chip>
      }
    </div>

    <ng-template pTemplate="footer">
      <div class="justify-content-center mt-1 flex gap-3">
        <p-button
          pRipple
          label="Cancel"
          severity="secondary"
          [outlined]="true"
          [disabled]="!editable"
          (onClick)="onCancel()"
        ></p-button>
        <p-button
          pRipple
          label="Save"
          severity="primary"
          [outlined]="true"
          [disabled]="!editable"
          (onClick)="onSave()"
        ></p-button>
      </div>
    </ng-template>
  </p-card>

  <div class="col"></div>
</div>
