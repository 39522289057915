import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  imports: [AvatarModule, ButtonModule, TabMenuModule, NgIf],
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.css',
})
export class MenuBarComponent implements OnInit {
  items: MenuItem[] | undefined;
  activeItem: MenuItem | undefined;
  loggedIn = false;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.loginEventEmitter.subscribe((loggedIn: boolean) => {
      this.onAuthEvent(loggedIn);
    });
  }

  ngOnInit() {
    this.items = [
      { label: 'Home', route: '/home', icon: 'pi pi-home' },
      { label: 'Existing Files', route: '/files', icon: 'pi pi-folder' },
      {
        label: 'Upload File',
        route: '/file-upload',
        icon: 'pi pi-cloud-upload',
      },
    ];
    this.activeItem = this.items[0];
    this.loggedIn = this.authService.authenticated;
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }

  onAvatarClick() {
    console.log('Avatar clicked');
  }

  onAuthEvent(event: boolean) {
    console.log('Auth event received. Updating state of loggedIn in menu-bar');
    this.loggedIn = event;
  }

  onLogin() {
    console.log('Navigating to login');
    this.router.navigate(['/login']);
  }

  onLogout() {
    console.log('Logging out');
    this.authService.logout();
  }
}
