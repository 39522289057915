import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(AuthService).getToken();
  const router = inject(Router);
  if (!token) {
    console.log('No token found. Calling loginRedirect()');
    inject(AuthService).loginRedirect();
  }
  const newReq = req.clone({
    headers: req.headers.append('Authorization', `Bearer ${token}`),
  });
  return next(newReq).pipe(
    catchError((err) => {
      if (err.status === 401) {
        console.log('Unauthorized request. Calling refreshToken()');
        router.navigate(['/login']);
      }
      throw err;
    }),
  );
};
