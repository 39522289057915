<!-- eslint-disable @angular-eslint/template/elements-content -->
<div class="card">
  <app-menu-bar></app-menu-bar>
</div>

<!-- https://primeng.org/table#column-selection -->

<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<app-search (onSearchChange)="onSearchChange($event)"></app-search>

<div class="justify-content-center flex p-3">
  <p-table
    [value]="files"
    styleClass="p-datatable-striped"
    [tableStyle]="{ 'min-width': '50rem' }"
    sortMode="multiple"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="file_name">
          Name <p-sortIcon field="file_name"></p-sortIcon>
        </th>
        <th>Link</th>
        <th pSortableColumn="size">
          Size <p-sortIcon field="size"></p-sortIcon>
        </th>
        <th pSortableColumn="upload_timestamp">
          Upload Time <p-sortIcon field="upload_timestamp"></p-sortIcon>
        </th>
        <th>Tags</th>
        <th>Delete</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file>
      <tr>
        <td>{{ file.file_name }}</td>
        <td>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-info-circle"
            class="p-button-rounded p-button-secondary"
            (click)="navigateToFileMetadata(file)"
          ></button>
        </td>
        <td>{{ file.size }}</td>
        <td>{{ file.upload_timestamp }}</td>
        <td>{{ file.tags_list }}</td>
        <td>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-danger"
            [outlined]="true"
            (click)="confirmDelete(file)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
