import { Component, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    FloatLabelModule,
    ReactiveFormsModule,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css',
})
export class SearchComponent {
  searchValue = new FormControl('');
  onSearchChange = output<string | null>();
  loading = false;

  setSearchTag() {
    this.onSearchChange.emit(this.searchValue.value);
  }

  onInput() {
    console.log('onInput()');
  }

  onButtonClick() {
    this.setSearchTag();
    this.load();
  }

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 500);
  }
}
