<div class="card">
  <app-menu-bar></app-menu-bar>
</div>

<p-toast />

<div class="m-8 px-8">
  <p-card header="Upload Image">
    <p-card>
      <div class="flex grid">
        <div class="col align-items-center justify-content-center flex">
          <p-fileUpload
            mode="basic"
            chooseLabel="Choose File to Upload"
            chooseIcon="pi pi-upload"
            maxFileSize="10000000"
            [customUpload]="true"
            [multiple]="false"
            [disabled]="formInvalid"
            (onSelect)="onSelect($event)"
            (uploadHandler)="uploadHandler($event)"
            (onUpload)="resetForm()"
          />
        </div>
        <!--if file is selected then tags are shown-->
        @if (fileSelected) {
          <div class="col">
            <h3>File Metadata</h3>
            <p-floatLabel>
              <input
                id="tags"
                type="text"
                class="form-control"
                pInputText
                [formControl]="tags"
                (input)="checkValid()"
              />
              <label for="username">Enter tags</label>
            </p-floatLabel>
          </div>
        }
      </div>
    </p-card>
  </p-card>
</div>
