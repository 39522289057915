import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { MenuBarComponent } from '../menu-bar/menu-bar.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CardModule,
    MenuBarComponent,
    PasswordModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
  ],
  providers: [Router],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  username = new FormControl('');
  password = new FormControl('');
  invalidForm = true;
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
    this.auth.loginEventEmitter.subscribe((authenticated) => {
      if (authenticated) {
        this.onLoginSuccess();
      }
    });
    this.auth.clearToken();
  }

  onLogin() {
    console.log('Login button clicked');
    if (!this.username.value || !this.password.value) {
      this.invalidForm = true;
      return;
    } else {
      this.invalidForm = false;
    }
    console.log('Calling auth.login()');
    this.auth.login(this.username.value, this.password.value);
  }

  onLoginSuccess() {
    console.log('Login successful');
    this.router.navigate(['/home']);
  }
}
